//jquery.validate.min.js　カスタマイズ用
$(function () {
  if ($(".diagnosisform").length) {
    //フォーム指定
    $(".diagnosisform").validate({
      //検証ルール設定
      rules: {
        //ここに検証ルールを設定(name属性を指定)
        corpname: {
          required: true,
        },
        familyname: {
          required: true,
        },
        givenname: {
          required: true,
        },
        familynamekana: {
          required: true,
        },
        givennamekana: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        ckemail: {
          required: true,
          email: true,
          // equalTo: "input[name=mail]",
        },
        purpose: {
          required: true,
        },
        comment: {
          required: true,
        },
      },

      //エラーメッセージ設定
      messages: {
        //ここにエラーメッセージを設定
        corpname: {
          required: "事務所名、会社名を入力してください",
        },
        familyname: {
          required: "お名前を入力してください",
        },
        givenname: {
          required: "お名前を入力してください",
        },
        familynamekana: {
          required: "フリガナを入力してください",
        },
        givennamekana: {
          required: "フリガナを入力してください",
        },
        email: {
          required: "メールアドレスを入力してください",
          email: "メールアドレスを正確に入力してください",
        },
        ckemail: {
          required: "確認用のメールアドレスを入力してください",
          email: "確認用のメールアドレスを正確に入力してください",
          // equalTo: "メールアドレスが一致しません",
        },
        purpose: {
          required: "簡易診断書作成の目的を入力してください",
        },
        comment: {
          required: "その他お問い合わせ詳細を入力してください",
        }
      },
      groups: {
        name: "familyname givenname",
        kana: "familynamekana givennamekana"
      },
      //エラーメッセージ出力箇所設定
      errorPlacement: function (error, element) {
        //ここにエラーメッセージの出力箇所を設定
        if (element.attr("name") == "familyname" || element.attr("name") == "givenname") {
          error.insertAfter("#name");
        } else if (element.attr("name") == "familynamekana" || element.attr("name") == "givennamekana") {
          error.insertAfter("#kana");
        } else {
          error.insertAfter(element);
        }
      },

      //validateのチェックが通り、submitが行われるときに実行するハンドラ
      submitHandler: function (orgThis, form, ev) {
        grecaptcha.ready(function () {
          grecaptcha.execute('6LeBbc8qAAAAAK8q8ITkwHTxFXvn2sozXVg8q196', { action: 'submit' }).then(function (token) {
            const myform = document.querySelector('#recaptcha_diagnosis_form');
            const token_input = document.createElement('input');
            token_input.type = 'hidden';
            token_input.name = 'recaptcha_response';
            token_input.value = token;
            myform.appendChild(token_input);
            myform.submit();
          });
        });
      },
    });
  }
  if ($(".magazineform").length) {
    //フォーム指定
    $(".magazineform").validate({
      //検証ルール設定
      rules: {
        //ここに検証ルールを設定(name属性を指定)
        familyname: {
          required: true,
        },
        givenname: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        chkemail: {
          required: true,
          email: true,
          equalTo: "input[name=mail]",
        },
        comment: {
          required: true,
        },
      },

      //エラーメッセージ設定
      messages: {
        //ここにエラーメッセージを設定
        familyname: {
          required: "姓を入力してください",
        },
        givenname: {
          required: "名を入力してください",
        },
        email: {
          required: "メールアドレスを入力してください",
          email: "メールアドレスを正確に入力してください",
        },
        chkemail: {
          required: "確認用のメールアドレスを入力してください",
          email: "確認用のメールアドレスを正確に入力してください",
          equalTo: "メールアドレスが一致しません",
        },
        comment: {
          required: "お問い合わせ内容を入力してください",
        }
      },

      //エラーメッセージ出力箇所設定
      errorPlacement: function (error, element) {
        //ここにエラーメッセージの出力箇所を設定
        //ラジオボタン
        if (element.attr("name") === "radio") {
          error.insertAfter("#radioErr");
        //チェックボックス
        } else if (element.attr("name") === "checkbox[]") {
          error.insertAfter("#checkboxErr");
          //標準出力箇所（フォーム項目の後にエラーメッセージを出力）
        } else {
          error.insertAfter(element);
        }
      },

      //validateのチェックが通り、submitが行われるときに実行するハンドラ
      submitHandler: function (orgThis, form, ev) {
        grecaptcha.ready(function () {
          grecaptcha.execute('6LeBbc8qAAAAAK8q8ITkwHTxFXvn2sozXVg8q196', { action: 'submit' }).then(function (token) {
            const myform = document.querySelector('#recaptcha_form');
            const token_input = document.createElement('input');
            token_input.type = 'hidden';
            token_input.name = 'recaptcha_response';
            token_input.value = token;
            myform.appendChild(token_input);
            myform.submit();
          });
        });
      },
    });
  }
  if ($(".validationform").length) {
    //フォーム指定
    $(".validationform").validate({
      rules: {
        "type": {
          required: true
        },
        "request[]": {
          required: true
        },
        "family-name": {
          required: true
        },
        "given-name": {
          required: true
        },
        "email": {
          required: true,
          email: true
        },
        "tel": {
          required: false, // 必須ではない場合はfalseに設定
        },
        "comment": {
          required: true
        }
      },
      messages: {
        "type": {
          required: "お問い合わせの種類を選択してください"
        },
        "request[]": {
          required: "ご希望のサービスを選択してください"
        },
        "family-name": {
          required: "お名前を入力してください"
        },
        "given-name": {
          required: "お名前を入力してください"
        },
        "email": {
          required: "メールアドレスを入力してください",
          email: "有効なメールアドレスを入力してください"
        },
        "comment": {
          required: "ご相談内容を入力してください"
        }
      },
      groups: {
        name: "family-name given-name"
      },
      //エラーメッセージ出力箇所設定
      errorPlacement: function (error, element) {
        //ここにエラーメッセージの出力箇所を設定
        //ラジオボタン
        if (element.attr("name") === "type") {
          error.insertAfter("#type");
        //チェックボックス
        } else if (element.attr("name") === "request[]") {
          error.insertAfter("#request");
          error.attr('id', 'request-error');
        //姓名
        } else if (element.attr("name") == "family-name" || element.attr("name") == "given-name") {
          error.insertAfter("#name");
        } else {
          //標準出力箇所（フォーム項目の後にエラーメッセージを出力）
          error.insertAfter(element);
        }
      },
      //validateのチェックが通り、submitが行われるときに実行するハンドラ
      submitHandler: function (orgThis, form, ev) {
        grecaptcha.ready(function () {
          grecaptcha.execute('6LeBbc8qAAAAAK8q8ITkwHTxFXvn2sozXVg8q196', { action: 'submit' }).then(function (token) {
            const myform = document.querySelector('#recaptcha_contact_form');
            const token_input = document.createElement('input');
            token_input.type = 'hidden';
            token_input.name = 'recaptcha_response';
            token_input.value = token;
            myform.appendChild(token_input);
            myform.submit();
          });
        });
      },
    });
  }
});
